defineDs('DanskeSpil/Domain/Oddset/Scripts/GameIframe', [
  'Common/Framework/EventHandling/Scripts/Event',
  'DanskeSpil/Project/Design/Js/Global/HashChange'
], function (Event, HashChange) {

  // Variables:
  var $iframe = null;

  // Functions:
  var refreshHeight = function (params) {
    var h = 0;

    if (params && params.height) {
      h = params.height;
    } else {
      try {
        h = $iframe.contentDocument.body.scrollHeight;
      } catch (e) {
        console.trace('GameFrame.refreshHeight', e);
      }
    }

    if (h > 0) {
      $iframe.style.height = h + 'px';
    }
  };

  var matchesObHash = function (hash) {
    /* Returns true, if the current hash does match a Game Vertical hash */
    return /^#?\/(kkr|tps|mlt|ob)\//g.test(hash); // allowed OB hashes
  };

  var init = function () {
    $iframe = document.querySelector('.js-oddset-game-iframe');

    if (!$iframe) {
      return;
    }

    var startUrl = $iframe.getAttribute('data-iframe-url'); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    var hash = location.hash || '';
    var iframeUrl = startUrl + hash;

    // workaround to ensure async iframe loading in all browsers
    setTimeout(function () {
      $iframe.setAttribute('src', iframeUrl); // eslint-disable-line no-jquery/no-other-methods -- Reason: Not a jquery selector
    }, 1);

    Event.subscribe('dsapi-update-game-frame-height', function (data) {
      refreshHeight(data);
    });

    // Custom help-overlay for Oddset region
    HashChange.match('oddset/help', function () {
      /* Forward to #p/oddset/help - used by Openbet */
      location.hash = '#p/oddset/help';
    });

    // Setup hashchange matcher
    HashChange.setGameVerticalHashMatcher(matchesObHash);
  };

  // Init:
  if (document.body.classList.contains('mode-normal')) {
    init();
  }

});


