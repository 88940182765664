defineDs('DanskeSpil/Framework/TeamsAndLeagues/Scripts/TeamsAndLeaguesLinks', [
  'DanskeSpil/Project/Design/Js/Global/HashChange',
  'DanskeSpil/Domain/Authentification/Scripts/LoginController',
  'DanskeSpil/Domain/Authentification/Scripts/SitecoreAuth',
  'Shared/Framework/Mithril/Scripts/Helpers/Utils'
], function (HashChange, LoginController, SitecoreAuth, Utils) {

  /** ******************************************************************
 * Translate URLs comming from links from create and change buttons
 * in Oddset or Tips in the form:
 * "Opret hold", "Opret liga", "Rediger hold", "Rediger liga"
 * https://town21.danskespil.dk/tips/konkurrencer/29#hold/opret/konkurrenceId=29/konkurrenceNavn=Future%20Pools%20Leaderboard/slutDato=2020-01-29T09:55:00.000Z
 * https://town21.danskespil.dk/tips/konkurrencer/29#liga/opret/konkurrenceId=29/konkurrenceNavn=Future%20Pools%20Leaderboard
 * into the form used by pam.
 * https://town21.danskespil.dk//tal/createteam#/create?competitionId=29/konkurrenceNavn=05%20-%20TestPAM%20May%202018/slutDato=2018-05-31T21:58:00.000Z&returnUrl=https%3A%2F%2Fweb.trunk.danskespil.dk%2Foddset%2Fkonkurrencer%2F22
 */

  var getCompetitionID = function (str) {
    var splitCharacter = '/';
    var needle = 'konkurrenceId=';
    var index = str.indexOf(needle);
    if (index === -1) {
      needle = 'konkurrencer/';
      index = str.indexOf(needle);
      splitCharacter = '#';
    }

    var sub = str.substring(index + needle.length);
    var id = sub.split(splitCharacter)[0];

    return parseInt(id, 10);
  };

  var getLeagueID = function (str) {
    var index = str.indexOf('ligaId=');
    if (index === -1) {
      return null;
    }
    var sub = str.substring(index + 'ligaId='.length);
    var id = sub.split('/')[0];
    return parseInt(id, 10);
  };

  var getTeamID = function (str) {
    var index = str.indexOf('holdId=');
    if (index === -1) {
      return null;
    }
    var sub = str.substring(index + 'holdId='.length);
    var id = sub.split('/')[0];
    return parseInt(id, 10);
  };

  var redirect = function (url) {
    if (!url) {
      return;
    }

    Utils.logDeprecated('TeamsAndLeaguesLinks.js redirect'); // Added July 2019 by Micki

    SitecoreAuth.getLoginStatus(function (loggedInSitecore) {
      if (loggedInSitecore) {
        location.href = url;
      } else {
        LoginController.openLogin({
          loginSuccessUrl: url
        });
      }
    });
  };

  /**
   * Test URLs from Openbet
   * Create team: "https://town23.danskespil.dk/oddset/konkurrencer/8#hold/opret/konkurrenceId=8/konkurrenceNavn=new%20lead%2007/05/18/slutDato=2019-05-01T11:24"
   * Edit team: "https://town23.danskespil.dk/oddset/konkurrencer/8#hold/rediger/holdId=7160/slutDato=2020-01-29T09:50:00.000Z"
   */
  HashChange.match(/^hold\//, function (hash) {
    var params = hash.substring(5);
    var competitionId = getCompetitionID(window.location.href);
    var teamId = getTeamID(window.location.href);
    var returnUrl = window.location.href.split('#')[0];
    var newUrl = '';
    if (params.indexOf('opret') === 0) {
      newUrl = '/konkurrencer/hold/opret?competitionId=' + competitionId + '&returnUrl=' + encodeURIComponent(returnUrl) + '#/create';
    } else if (params.indexOf('rediger') === 0) {
      newUrl = '/konkurrencer/hold/oversigt?teamId=' + teamId + '&returnUrl=' + encodeURIComponent(returnUrl) + '#/overview';
    }

    redirect(newUrl);
  });

  /**
   * Test URLs from Openbet
   * Create league: "https://town23.danskespil.dk/oddset/konkurrencer/8#liga/opret/konkurrenceId=8/konkurrenceNavn=Future%20Oddset%20Leaderboard"
   * Edit league: "https://town23.danskespil.dk/oddset/konkurrencer/8#liga/rediger/ligaId=7204"
   */
  HashChange.match(/^liga\//, function (hash) {
    var params = hash.substring(5);
    var competitionId = getCompetitionID(window.location.href);
    var leagueId = getLeagueID(window.location.href);
    var returnUrl = window.location.href.split('#')[0];
    var newUrl = '';
    if (params.indexOf('opret') === 0) {
      newUrl = '/konkurrencer/liga/opret?competitionId=' + competitionId + '&returnUrl=' + encodeURIComponent(returnUrl) + '#/create';
    } else if (params.indexOf('rediger') === 0) {
      newUrl = '/konkurrencer/liga/oversigt?leagueId=' + leagueId + '&returnUrl=' + encodeURIComponent(returnUrl) + '#/overview';
    }

    redirect(newUrl);
  });
});
