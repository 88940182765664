defineDs('DanskeSpil/Domain/OpenBet/Scripts/DigitalPortalLoader', function () {

  // Variables:
  var $element = document.getElementById('digital-portal-wrapper');

  // Context:
  if ($element) {
    var $enablePools = $element.dataset.enablePools === 'True' || false;
    var listeners = [
      '@sgd/account-activity/READY',
      'openbet-portal-core/content/CMS_CONTENT_READY',
      '@dan/headless-sportsbook/content/CMS_CONTENT_READY',
      '@sgd/headless-sportsbook/route/NOT_FOUND',
      'portal.login.statusChanged'
    ];
    window.SGDPortal = {
      q: [],
      e: [],
      l: listeners,
      load: function (e, n, c) {
        window.SGDPortal.q.push([e, n, c]);
      },
      h: function (e) {
        window.SGDPortal.e.push(e);
      }
    };

    for (var i in listeners) {
      window.addEventListener(listeners[i], window.SGDPortal.h);
    }

    var script = document.createElement('script');

    script.onload = function () {
      var basePath =  '/' + window.location.pathname.split('/')[1];

      // Old namespace:
      if (window.__OpenbetAppConfig__) {
        window.__OpenbetAppConfig__.basePath = basePath;
      }

      // New namespace:
      if (window.__SGD__ && window.__SGD__.webapp) {
        window.__SGD__.webapp.basePath = basePath;
        window.__SGD__.webapp.operatorSpecificConfig = { ...window.__SGD__.webapp.operatorSpecificConfig, ...{ operatorVariation: 'oddset' } };
      }

      if ($enablePools) {
        window.__SGD__.webapp.operatorSpecificConfig = { ...window.__SGD__.webapp.operatorSpecificConfig, ...{ operatorVariation: 'pools' }   };
      }
      window.SGDPortal.load('#digital-portal-wrapper', 'sportsbook', { route: '/' });
    };

    script.async = true;
    script.src = $element.dataset.digitalPortalLoaderUrl;

    document.head.appendChild(script);
  }
});
